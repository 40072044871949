<template>
  <main class="main_v8 libraryinfo_home">
    <breadcrumb></breadcrumb>

    <div class="plus_line_top" v-if="edit" @click="addIndex = -1; addLine = true;">Add Property</div>

    <p draggable="true" v-if="this.edit && beforeLoad && lines.length == 0" class="no_content no_content_edit functional" style="margin-top: 6px;">
      You don’t have any properties. Please click the Add Properties button to get started.
    </p>
    <p v-if="!this.edit & beforeLoad && lines.length == 0" class="no_content functional" style="margin-top: 16px;">
      You don’t have any properties.
      To get started, make sure you have editor privileges. Once you do, click on the menu icon in the upper left hand corner:<br/>
      <i></i>
      Once you’ve clicked that icon, you will see a button just under your name that says “Edit Mode”. Click it and you’ll have the ability to add properties.
    </p>

    <draggable
      v-model="lines"
      @change="saveInfo"
      ghost-class="ghost-card"
      :animation="200"
      handle=".dragger"
    >
      <transition-group>
        <div class="line" v-for="(line, index) in lines" :key="line.id" :class="{ line_edit: edit }"
        >
          <div class="dragger" v-if="edit"></div>
          <input type="text" v-model="line.name" class="linetitle"
            :readonly="edit ? false : true"
            :style="[edit ? { textIndent : '16px', height: '40px', lineHeight: '40px'} : {}]"
          >
          <span
            :contenteditable="edit ? true : false"
            class="linevalue" type="text" v-text="line.value"
            :readonly="edit ? false : true"
            :style="[windowWidth <= 600 ? { backgroundColor: 'rgba(120, 120, 120, 0.05)' } : {}]"></span>
          <transition name="slide-right">
            <span v-if="edit" class="delete_line" @click="deleteLine = index"></span>
          </transition>
          <div class="plus_line" v-if="edit" @click="addIndex = index; addLine = true;">Add Property</div>
        </div>
      </transition-group>
    </draggable>

    <div class="add_line universal_modal" v-if="addLine">
      <h2>Add New Library Property</h2>
      <input type="text" @keypress="isEmpty" v-model="newName" placeholder="Enter Property Name">
      <input type="text" @keypress="isEmpty" v-model="newValue" placeholder="Enter Property Value">
      <div class="btns">
        <btn class="button" v-text="'add'" :class="{ 'disabled': newName == '' || newValue == '' }" @click="addNew"></btn>
        <btn class="button secondary" v-text="'cancel'" @click="addLine = false; newName = ''; newValue = '';"></btn>
      </div>
    </div>

    <div class="delete_modal universal_modal" v-if="deleteLine >= 0">
      <h2>Deletion Warning</h2>
      <div class="btns">
        <btn class="button secondary" v-text="'cancel'" @click="deleteLine = -1; addIndex = '', newName = '', newValue = ''"></btn>
        <btn class="button" v-text="'confirm deletion'" @click="remove"></btn>
      </div>
    </div>

    <div class="context_shadow" v-if="addLine || deleteLine >= 0 || breadcrumbPopup" @click.stop="addLine = false; deleteLine = -1; breadcrumbPopup = false; addIndex = '', newName = '', newValue = ''"></div>

    <div class="breadcrumb_popup" v-if="breadcrumbPopup">
      <p>Your Location:</p>
      <breadcrumbtree></breadcrumbtree>
      <btn class="secondary" v-text="'close'" @click="breadcrumbPopup = false"></btn>
    </div>

  </main>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import btn from '../components/buttons/button.vue';
import Breadcrumbtree from '../components/breadcrumbtree/breadcrumbtree.vue';
import breadcrumb from '../components/breadcrumbtree/breadcrumb.vue';
  
export default {
  name: 'machineInfo',
  inject: ['apiPath'],
  components: {
    draggable: VueDraggableNext,
    btn,
    Breadcrumbtree,
    breadcrumb,
  },
  data() {
    return {
      addIndex: '',
      deleteLine: -1,
      addLine: false,
      title: '',
      lines: [],
      linesClone: [],
      dragImg: '../../public/img/delete.svg',
      newName: '',
      newValue: '',
      breadcrumbPopup: false,
      tooLong: false,
      windowWidth: window.innerWidth,
      beforeLoad: false,
    };
  },
  computed: {
    edit() {
      return this.$store.state.edit;
    },
    breadcrumb() {
      if (!this.$store.state.targetPath && this.$route.name !== 'Home' && localStorage.location !== undefined) {
        return JSON.parse(localStorage.location)
      } else {
        return this.$store.state.targetPath
      }
    },
    isEmpty() {
      if (this.newName === '' || this.newValue === '') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    here() {
      console.log(this.lines.length)
    },
    async sendFetch(url, data = null) {
      let formData = new FormData();
      formData.append('token', this.$store.state.usertoken);
      if (data !== null) {
        Object.keys(data).forEach(function(key) {
          formData.append(key, data[key]);
        });
      }
      return await fetch(`${this.apiPath}` + url, {
        method: 'POST',
        body: formData,
      }).then((res) => res.json()).then((response) => {
        if (response.result) {
          return response.result;
        } else {
          console.log('Looks like there was a problem: \n', response.error);
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          return false;
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
        return false;
      });
    },
    saveInfo() {
      this.sendFetch(
          'entity/location/edit',
          {
            "slocationid": this.$route.params.id,
            "machineInfo": JSON.stringify(this.lines),
          }
      );
    },
    remove() {
      this.lines.splice(Number(this.deleteLine), 1);
      this.deleteLine = -1;
      this.saveInfo();
    },
    addNew() {
      if (!this.isEmpty) {
        let position = 0;
        if (this.addIndex === '') {
          position = this.lines.length;
        } else { 
          position = this.addIndex + 1;
        }
        this.lines.splice(position, 0, {id: position, name: this.newName, value: this.newValue });
        this.addLine = false;
        this.newName = '';
        this.newValue = '';
        this.saveInfo();
      }
    },
    getData() {
      fetch(`${this.apiPath}machineInfo?locationid=${this.$route.params.id}&token=${this.$store.state.usertoken}`, {
        headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        if (response.error === undefined) {
          this.beforeLoad = true;
          this.title = response.title;
          this.subtitle = response.subtitle;
          this.lines = JSON.parse(response.machineinfo);
          this.lines.forEach(function (e, index) {
            e.id = index;
          });
          this.linesClone = JSON.parse(response.machineinfo);
        } else {
          if (response.error === 'Wrong token') {
            window.location.reload();
          }
          if (response.error === '403') {
            window.location.href = "/403";
          }
        }
      }).catch((error) => {
        console.log('Looks like there was a problem: \n', error);
      });
    },
  },
  
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.no_content{
  margin-left: 200px;
  width: calc(100% - 230px);
  position: relative;
  max-width: none;
  padding: 8px 16px;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    width: calc(100% - 190px);
    margin-left: 160px;
  }
}
.no_content::before{
  position: absolute;
  content: 'Example Property';
  left: -200px;
  color: #252525;
  font-size: 1rem;
  font-weight: 400;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    left: -160px;
  }
}
.no_content_edit{
  margin-left: 220px;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    width: calc(100% - 210px);
    margin-left: 180px;
  }
}
.no_content_edit::before{
  left: -220px;
  top: 50%;
  transform: translateY(-50%);
  text-indent: 20px;
  cursor: grab;
  background-image: url(../../public/img/libraryinfo/dragger_libraryinfo.svg);
  background-position: left center;
  background-repeat: no-repeat;
  height: 32px;
  line-height: 32px;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    left: -180px;
    top: 0px;
    transform: translateY(0);
  }
}
.plus_line,.plus_line_top{
  cursor: pointer;
  height: 46px;
  line-height: 46px;
  padding-left: 34px;
  background-image: url(../../public/img/libraryinfo/add_line.svg);
  background-repeat: no-repeat;
  background-position: center left;
  font-size: 1rem;
  color: #787878;
  width: 180px;
  box-sizing: border-box;
}
.plus_line{
  position: absolute;
  bottom: -46px;
  left: calc(50% - 90px);
}
.plus_line_top{
  margin: 0 auto 0;
}
.breadcrumb_popup{
  position: fixed;
  width: 456px;
  max-width: calc(100% - 32px);
  background-color: #fff;
  z-index: 3;
  padding: 16px 32px 32px 32px;
  box-shadow: 0px 6px 20px 1px rgba(37, 37, 37, 0.04);
  border-radius: 16px;
  top: 50%;
  left: 50%;
  margin-left: -228px;
  transform: translateY(-50%);
  box-sizing: border-box;
  text-align: center;
  .breadcrumbtree{
    position: relative;
    background-color: #f8f8f8;
    left: 0px;
    right: 32px;
    border-radius: 8px;
    padding: 1px 0;
    text-align: left;
    max-height: calc(80% - 150px);
  }
  p{
    margin: 0px 0px 16px 0px;
    color: #252525;
    font-weight: 500;
    text-align: left;
  }
  .button{
    margin-top: 32px;
  }
}
.delete_modal{
  background-image: url(../../public/img/trash.png);
  background-repeat: no-repeat;
  background-position: center 80px;
  height: 370px;
}
.add_line,.delete_modal{
  width: 456px;
  position: fixed;
  padding: 32px 32px 115px;
  border-radius: 16px;
  background-color: #fff;
  z-index: 3;
  box-sizing: border-box;
  font-size: 14px;
  h2{
    font-size: 24px;
    margin: 0 0 32px 0;
  }
  input{
    font-size: 1rem;
    display: block;
    margin-bottom: 16px;
    border: none;
    outline: none;
    height: 40px;
    background-color: rgba(120, 120, 120, 0.05);
    border-radius: 8px;
    text-indent: 8px;
    width: 100%;
  }
  .btns{
    text-align: center;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0px;
    display: flex;
    padding: 0 32px;
    box-sizing: border-box;
    .button{
      padding: 0;
    }
    :first-child{
      width: 100px;
      margin-right: 16px;
    }
    :last-child{
      flex: 1;
    }
  }
}
.context_shadow {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  transition: all .3s;
}
.delete_line{
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  line-height: 40px;
  color: #787878;
  font-size: 14px;
  background-image: url(../../public/img/delete.svg);
  cursor: pointer;
}
.main_v8{
  top: 64px;
  padding-top: 50px;
  @media screen and (min-width: 481px) and (max-width: 900px){
    top: 52px;
  }
}
.libraryinfo_home{
  .page_header{
    margin-top: 11px;
    margin-bottom: 0px;
  }
  .page_subtitle{
    margin-top: 2px;
    margin-bottom: 12px;
    color: rgba(120, 120, 120, 1);
    line-height: 16px;
    font-size: 12px;
  }
}
.line{
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  transition: all 0.3s ease-out;
  position: relative;
}

.line_edit{
  margin-bottom: 44px;
}
.dragger{
  cursor: grab;
  background-image: url(../../public/img/libraryinfo/dragger_libraryinfo.svg);
  background-position: 5px center;
  background-repeat: no-repeat;
  width: 40px;
}
.linevalue,.linetitle {
  display: inline-block;
  height: 40px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  transition: all 0.3s ease-out;
  border: none;
  outline: none;
  line-height: 40px;
}
.linetitle {
  font-weight:500;
  color: #252525;
  width: 174px;
  margin: 0 10px 0 0;
  box-sizing: border-box;
  background: none;
}
.linevalue {
  overflow: hidden;
  height: fit-content;
  background-color:white;
  color: #787878;
  font-weight: 400;
  width: 100%;
  text-indent: 16px;
  padding: 0px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.02);
}
.linevalue:read-only{
  cursor: auto;
}
.add_section_button{
  position: fixed;
  z-index: 1;
  bottom: 20px;
  left: 50%;
  width: 320px;
  max-width: 90%;
  padding: 0 24px 0 35px;
  margin-left: -160px;
}
.slide-right-enter-active, 
.slide-right-leave-active {
  transition: all 0.3s ease-out;
}
.slide-right-enter-from,
.slide-right-leave-to {
  margin-right: -40px;
}
@media screen and (max-width: 599px) and (orientation: portrait){
  .breadcrumb_popup{
    left: 16px;
    margin-left: auto;
  }
}
@media screen and (max-width: 480px) and (orientation: portrait), screen and (max-height: 480px) and (orientation: landscape){
  .plus_line,.plus_line_top{
    height: 36px;
    line-height: 36px;
    padding-left: 34px;
    background-image: url(../../public/img/libraryinfo/add_line.svg);
    background-repeat: no-repeat;
    background-position: center left;
    font-size: 1rem;
    color: #787878;
    width: fit-content;
  }
  .plus_line{
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
  }
  .plus_line_top{
    width: fit-content;
    margin: 0 auto 5px;
  }
  .add_line{
    max-width: calc(100% - 32px);
    .btns{
      position: unset;
      padding: 0px;
      display: block;
      .button{
        width: 100%;
        margin: 0px;
        display: block;
      }
      :first-child{
        margin-bottom: 16px;
      }
    }
  }
  .delete_line{
    height: 30px;
    position: absolute;
    left: 0px;
    top: 0px;
    box-shadow: none;
  }
  .main_v8{
    top: 44px;
    bottom: 58px;
    padding-top: 50px;
  }
  .line{
    background-color: #fff;
    height: fit-content;
    padding: 10px;
    border-radius: 16px;
    margin-bottom: 16px;
    display: block;
    position: relative;
    .linevalue,.linetitle{
      background-color: rgba(120, 120, 120, 0.05);
    }
  }
  .linetitle {
    font-weight:500;
    height: 24px;
    display: block;
    line-height: 24px;
    margin-bottom: 8px;
    height: 40px;
    width: 100%;
    text-indent: 16px;
  }
  .linevalue {
    background-color:white;
    color: #787878;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
  }
  .line_edit{
    height: 140px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 44px;
    .dragger{
      background-image: url(../../public/img/book/dragger.svg);
      background-position: center;
      height: 24px;
      margin-bottom: 10px;
      width: 100%;
    }
    input{
      box-shadow: none;
    }
    .linetitle {
      width: 100%;
      margin-left: 0 !important;
    }
    .delete_line{
      height: 40px;
      font-size: 1rem;
    }
  }
  .delete_modal{
    height: 425px;
    .btns{
      position: absolute;
      padding: 0px;
      display: block;
      left: 24px;
      right: 24px;
      bottom: 24px;
        width: auto;
      .button{
        width: 100%;
        margin: 0px;
        display: block;
      }
      :first-child{
        margin-bottom: 16px;
      }
    }
  }
  .add_section_button{
    display: none;
  }
}
// @media screen and (max-height: 500px) and (orientation: landscape){
//   .add_line{
//     top: 15px;
//   }
//   .delete_modal{
//     top: 15px;
//     max-height: calc(100% - 20px);
//     background-size: 23%;
//   }
// }
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  box-sizing: border-box;
}
</style>
